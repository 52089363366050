import { render, staticRenderFns } from "./CreateProductModal.vue?vue&type=template&id=11088b45&"
import script from "./CreateProductModal.vue?vue&type=script&lang=js&"
export * from "./CreateProductModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.caskadepro.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11088b45')) {
      api.createRecord('11088b45', component.options)
    } else {
      api.reload('11088b45', component.options)
    }
    module.hot.accept("./CreateProductModal.vue?vue&type=template&id=11088b45&", function () {
      api.rerender('11088b45', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/product-modal/CreateProductModal.vue"
export default component.exports